import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { useDispatch } from 'react-redux';
import { startRequest } from '../../../../actions/apiCall';
import { showFixedLabel } from '../../../../actions/fixedLabel';
import { GetSessionStorage } from '@backoffice/common/utils/storageManager';
import { Input, Button } from '@backoffice/components/FormComponents/index';
import {
	getBuildingDocumentsByIdParams,
	downloadDocumentByIdParams
} from './DocumentsTenantCalls/DocumentsTenantCalls';
import './DocumentsTenant.scss';
import { getFileExtension, parseDate } from '../../../../common/utils/parseUtils';

const DocumentsTenant = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const isMobile = useMediaQuery({ query: '(max-width: 450px)' });

	const documentTabs = [ 'Contracts', 'Invoices', 'Others' ];
	const subTypes = [ 'Light', 'Water', 'Gas' ];
	let searchBy = t('Search') + ' ' + t('by') + ' ' + t('WordMin');

	const [ currentTab, setCurrentTab ] = useState(documentTabs[0]);

	const [ contractDocuments, setContractDocuments ] = useState([]);
	const [ invoicesDocuments, setInvoicesDocuments ] = useState([]);
	const [ otherDocuments, setOtherDocuments ] = useState([]);

	const [ showDocuments, setShowDocuments ] = useState([]);

	const [ search, setSearch ] = useState('');
	const [ subType, setSubType ] = useState(subTypes[0]);

	const [ buildingId, setBuildingId ] = useState(GetSessionStorage('PROFILE_').buildingId);

	useEffect(() => {
		getBuildingDocumentsById();
	}, []);

	useEffect(
		() => {
			switch (currentTab) {
				case documentTabs[0]:
					if (contractDocuments && contractDocuments.length > 0) {
						if (search) {
							setShowDocuments(
								contractDocuments.filter(
									(x) =>
										x.name.toUpperCase().includes(search.toUpperCase()) ||
										x.fileName.toUpperCase().includes(search.toUpperCase())
								)
							);
						} else {
							setShowDocuments(contractDocuments);
						}
					}
					break;
				case documentTabs[1]:
					if (invoicesDocuments && invoicesDocuments.length > 0) {
						if (search) {
							setShowDocuments(
								invoicesDocuments
									.filter((x) => x.documentSubTypeId === subType)
									.filter(
										(x) =>
											x.name.toUpperCase().includes(search.toUpperCase()) ||
											x.fileName.toUpperCase().includes(search.toUpperCase())
									)
							);
						} else {
							setShowDocuments(invoicesDocuments.filter((x) => x.documentSubTypeId === subType));
						}
					}
					break;
				case documentTabs[2]:
					if (otherDocuments && otherDocuments.length > 0) {
						if (search) {
							setShowDocuments(
								otherDocuments.filter(
									(x) =>
										x.name.toUpperCase().includes(search.toUpperCase()) ||
										x.fileName.toUpperCase().includes(search.toUpperCase())
								)
							);
						} else {
							setShowDocuments(otherDocuments);
						}
					}
					break;
				default:
					break;
			}
		},
		[ currentTab, search, subType ]
	);

	const getBuildingDocumentsById = () => {
		dispatch(startRequest(getBuildingDocumentsByIdParams(buildingId, getBuildingDocumentsByIdSuccess)));
	};

	const getBuildingDocumentsByIdSuccess = (response) => {
		setContractDocuments(response.documentContracts);
		setInvoicesDocuments(response.documentInvoices);
		setOtherDocuments(response.documentOthers);

		switch (currentTab) {
			case documentTabs[0]:
				setShowDocuments(response.documentContracts);
				break;
			case documentTabs[1]:
				setShowDocuments(response.documentInvoices);
				break;
			case documentTabs[2]:
				setShowDocuments(response.documentOthers);
				break;
			default:
				break;
		}
	};

	const downloadDocuments = (document) => {
		dispatch(
			startRequest(
				downloadDocumentByIdParams(
					buildingId,
					document.documentId,
					(response) => downloadDocumentSuccess(response, document),
					downloadDocumentError
				)
			)
		);
	};

	const downloadDocumentError = (response) => {
		dispatch(showFixedLabel('DownloadDocumentError', 'error'));
	};

	const downloadDocumentSuccess = (response, document) => {
		var sampleArr = base64ToArrayBuffer(response);
		openWindowByteArray(sampleArr, document);
	};

	const openWindowByteArray = (byte, document) => {
		var blob = new Blob([ byte ], { type: document.mimeType });
		window.open(window.URL.createObjectURL(blob), '_blank');
	};

	const base64ToArrayBuffer = (base64) => {
		var binaryString = window.atob(base64);
		var binaryLen = binaryString.length;
		var bytes = new Uint8Array(binaryLen);
		for (var i = 0; i < binaryLen; i++) {
			var ascii = binaryString.charCodeAt(i);
			bytes[i] = ascii;
		}
		return bytes;
	};

	const Tab = (doc) => {
		let btnClass1 = 'tab-buttons-btn btn-base_tab-btn';
		let btnClass2 = 'tab-buttons-btn btn-base_tab-btn';
		let btnClass3 = 'tab-buttons-btn btn-base_tab-btn';

		switch (subType) {
			case subTypes[0]:
				btnClass1 += '-active';
				break;
			case subTypes[1]:
				btnClass2 += '-active';
				break;
			case subTypes[2]:
				btnClass3 += '-active';
				break;
			default:
				break;
		}

		return (
			<div className="tab">
				<h2 className="title" hidden={true}>
					{t('BuildingDocumentation')}
				</h2>
				<div className="row tab-buttons">
					{currentTab === documentTabs[1] && (
						<React.Fragment>
							<Button
								btnText={t('Electricity')}
								btnClass={btnClass1}
								onHandleBtnClick={() => {
									setSubType(subTypes[0]);
								}}
							/>
							<Button
								btnText={t('Water')}
								btnClass={btnClass2}
								onHandleBtnClick={() => {
									setSubType(subTypes[1]);
								}}
							/>
							<Button
								btnText={t('Gas')}
								btnClass={btnClass3}
								onHandleBtnClick={() => {
									setSubType(subTypes[2]);
								}}
							/>
						</React.Fragment>
					)}
					{/* <div className="btn-download-all">
            <Button
              btnText={t("DownloadAll")}
              dataTestID="btnDownloadAll"
              btnType="button"
              icon="download"
              btnClass="btn-base_outline export-excel-button"
              onHandleBtnClick={() => {}}
            />
          </div> */}
				</div>
				<div className="row">{renderDocuments(doc)}</div>
			</div>
		);
	};

	const renderDocuments = (documents) => {
		let html = [];
		if (documents.length > 0) {
			documents.forEach((doc, i) => {
				let name = doc.name !== '' ? doc.name : doc.fileName;

				if(doc.mediaURL !== ''){

					html.push(
						<a
						id={i + "-link-document"}
						href={doc.mediaURL}
						target="_blank"
						rel="noreferrer"
						className="col-md-3 container-document"
						key={i + '-document'}
						>
							<div className="body-wrapper">
								<span className="download-link" aria-label={t('Title') + ': ' + { name } + '.'}>
									<div className="download-link">{name}</div>
								</span>
								<p className="extension" aria-hidden={true}>
									{getFileExtension(doc.fileName)}
								</p>
								<p className="date">{parseDate(doc.creationDate)}</p>
							</div>
	
							{!isMobile ? (
								<div className="arrow-wrapper">
									<span
										className="material-icons-round"
										data-testid={'btn-goback'}
										id={'btn-goback'}
										aria-hidden={true}
									>
										arrow_forward
									</span>
								</div>
							) : (
								''
							)}
						</a>
					);

				} else {
					html.push(
						<div
							className="col-md-3 container-document"
							key={i + '-document'}
							onClick={() => downloadDocuments(doc)}
						>
							<div className="body-wrapper">
								<span className="download-link" aria-label={t('Title') + ': ' + { name } + '.'}>
									<div className="download-link">{name}</div>
								</span>
								<p className="extension" aria-hidden={true}>
									{getFileExtension(doc.fileName)}
								</p>
								<p className="date">{parseDate(doc.creationDate)}</p>
							</div>
	
							{!isMobile ? (
								<div className="arrow-wrapper">
									<span
										className="material-icons-round"
										data-testid={'btn-goback'}
										id={'btn-goback'}
										aria-hidden={true}
									>
										arrow_forward
									</span>
								</div>
							) : (
								''
							)}
						</div>
					);
				}
			});
		} else {
			html.push(
				<div className="col-md-3" key={'empty-document'}>
					{t('Empty')}
				</div>
			);
		}

		return html;
	};

	return (
		<div className="documents-tenant">
			<h1 className="title" hidden={true}>
				{t('Documents')}
			</h1>
			<div className="tabs-wrapper">
				<div id="tabs" className="tabs">
					{documentTabs.map((x) => {
						return (
							<button
								key={x}
								className={`tab-button ${currentTab === x ? 'active' : ''}`}
								aria-selected={currentTab === x}
								onClick={() => setCurrentTab(x)}
								aria-roledescription="tab"
								role="tab"
							>
								{t(x)}
							</button>
						);
					})}
					<div className="tabs-underline" />
				</div>
				<div className="tabs_form">
					<div className="form-content">
						<div className="tab-content-item" id="tab-content-item">
							<div className="searchInput">
								<Input
									dataTestID="txtSearch"
									inputName="txtSearch"
									clearInput={true}
									inputPlaceholder={searchBy}
									inputType="text"
									value={search}
									inputOutline={true}
									icon={'search'}
									iconLeft={true}
									isRequired={false}
									onHandleChange={(e) => setSearch(e.target.value)}
								/>
							</div>
							{showDocuments && Tab(showDocuments)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
export default DocumentsTenant;
