import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { startRequest } from "@backoffice/actions/apiCall";
import FilePreview from "../../../../../shared/FilePreview/FilePreview";
import Uploader from "../../../../../shared/Uploader/Uploader";
import { UploaderComponent } from "@syncfusion/ej2-react-inputs";
import { formatBytes, parseDate } from "../../../../../common/utils/parseUtils";
import { select } from "@syncfusion/ej2-base";
import { openModal } from "../../../../../actions/popup";
import { showFixedLabel } from "../../../../../actions/fixedLabel";
import { closeModal } from "../../../../../actions/popup";

import { Button } from "@backoffice/components/FormComponents/index";
import { useMediaQuery } from "react-responsive";
import PopUpImage from "./PopUpImage/PopUpImage";
import PopUpDeclineIncident from "./PopUpDeclineIncident/PopUpDeclineIncident";
import { Input } from "@backoffice/components/FormComponents/index";

import {
  postMediaParams,
  acceptIncidentParams,
  declineIncidentParams,
  changeAmountParams,
  getMediaByIdParams,
  finishIncidentByIdParams,
} from "../IncidentsProviderDetailCalls/IncidentsProviderDetailCalls";
import { useHistory } from "react-router-dom";
import { ROUTE } from "../../../../../common/constants/routes";

import "./TabGeneral.scss";

const TabGeneral = ({ incident, incidentId, handleSubmit }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [media, setMedia] = useState([]);
  const [mediaWithBinary, setMediaWithBinary] = useState([]);
  const [budgets, setBudgets] = useState([]);
  const [budgetsWithBinary, setBudgetWithBinary] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [invoicesWithBinary, setInvoicesWithBinary] = useState([]);
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1000px)" });
  const isMobile = useMediaQuery({ query: "(max-width: 575px)" });

  const [imagesUploader, setImagesUploader] = useState("");
  const [budgetsUploader, setBudgetsUploader] = useState("");
  const [invoicesUploader, setInvoicesUploader] = useState("");

  const [amount, setAmount] = useState("");

  useEffect(() => {
    setMediaWithBinary([]);
    setBudgetWithBinary([]);
    setInvoicesWithBinary([]);
    setMedia(incident && incident.documentMedia ? incident.documentMedia : []);
    setBudgets(
      incident && incident.documentBudget ? incident.documentBudget : []
    );
    setInvoices(
      incident && incident.documentInvoce ? incident.documentInvoce : []
    );
    setAmount(incident && incident.budget ? incident.budget : "");
  }, [incident]);

  useEffect(() => {
    if (media.length > 0 && media.length > mediaWithBinary.length) {
      getMediaById(media[mediaWithBinary.length].id, "media");
    }
  }, [mediaWithBinary]);

  useEffect(() => {
    if (budgets.length > 0 && budgets.length > budgetsWithBinary.length) {
      getMediaById(budgets[budgetsWithBinary.length].id, "budgets");
    }
  }, [budgetsWithBinary]);

  useEffect(() => {
    if (invoices.length > 0 && invoices.length > invoicesWithBinary.length) {
      getMediaById(invoices[invoicesWithBinary.length].id, "invoices");
    }
  }, [invoicesWithBinary]);

  //---------------------------------------------------------------------------
  //----[ GET MEDIA INCIDENT ]-------------------------------------------------
  //---------------------------------------------------------------------------

  const getMediaById = (mediaId, type) => {
    dispatch(
      startRequest(
        getMediaByIdParams(mediaId, incidentId, (response) =>
          getMediaByIdParamsSuccess(response, type)
        )
      )
    );
  };

  const getMediaByIdParamsSuccess = (response, type) => {
    if (type === "media") {
      let newMedia = [...mediaWithBinary];
      newMedia.push(response);
      setMediaWithBinary(newMedia);
    } else if (type === "budgets") {
      let newBudgets = [...budgetsWithBinary];
      newBudgets.push(response);
      setBudgetWithBinary(newBudgets);
    } else if (type === "invoices") {
      let newInvoices = [...invoicesWithBinary];
      newInvoices.push(response);
      setInvoicesWithBinary(newInvoices);
    }
  };

  //---------------------------------------------------------------------------
  //----[ POST INCIDENT ]------------------------------------------------------
  //---------------------------------------------------------------------------

  const postIncidentProviderMedia = () => {
    const imagesMediaData = getImagesMedia();
    if (imagesMediaData.Files.length > 0) {
      dispatch(
        startRequest(postMediaParams(imagesMediaData, postMediaParamsSuccess))
      );
    }

    const budgetsMediaData = getBudgetsMedia();
    if (budgetsMediaData.Files.length > 0) {
      dispatch(
        startRequest(postMediaParams(budgetsMediaData, postMediaParamsSuccess))
      );
    }

    const invoicesMediaData = getInvoicesMedia();
    if (invoicesMediaData.Files.length > 0) {
      dispatch(
        startRequest(postMediaParams(invoicesMediaData, postMediaParamsSuccess))
      );
    }

    if (amount != "") {
      dispatch(
        startRequest(
          changeAmountParams({ id: incidentId, budget: amount }, (x) => {
            x && history.go(0);
          })
        )
      );
    }
  };

  const postMediaParamsSuccess = () => {
    dispatch(showFixedLabel("MediaSaveSuccess", "success"));
    handleSubmit();
  };

  const getImagesMedia = () => {
    let newImagesMedia = [];
    imagesUploader.filesData.forEach((file) => {
      newImagesMedia.push({
        File: file.rawFile,
      });
    });

    return {
      Id: incidentId,
      Title: "Title",
      Description: "Description",
      MediaTypeEnum: "Media",
      Files: newImagesMedia,
    };
  };

  const getBudgetsMedia = () => {
    let newBudgetsMedia = [];
    budgetsUploader.filesData.forEach((file) => {
      newBudgetsMedia.push({
        File: file.rawFile,
      });
    });

    return {
      Id: incidentId,
      Title: "Title",
      Description: "Description",
      MediaTypeEnum: "Budget",
      Files: newBudgetsMedia,
    };
  };

  const getInvoicesMedia = () => {
    let newInvoicesMedia = [];
    invoicesUploader.filesData.forEach((file) => {
      newInvoicesMedia.push({
        File: file.rawFile,
      });
    });

    return {
      Id: incidentId,
      Title: "Title",
      Description: "Description",
      MediaTypeEnum: "Invoice",
      Files: newInvoicesMedia,
    };
  };

  //---------------------------------------------------------------------------
  //------[ FINISH INCIDENT ]--------------------------------------------------
  //---------------------------------------------------------------------------

  const finishIncident = () => {
    dispatch(
      startRequest(
        finishIncidentByIdParams(incidentId, finishIncidentByIdParamsSuccess)
      )
    );
  };

  const finishIncidentByIdParamsSuccess = (response) => {
    history.push(ROUTE.INCIDENTSPROVIDER);
    dispatch(showFixedLabel("IncidentClosedSuccess", "success"));
  };

  //---------------------------------------------------------------------------
  //------[ ACCEPT INCIDENT ]--------------------------------------------------
  //---------------------------------------------------------------------------

  const acceptIncident = () => {
    dispatch(
      startRequest(
        acceptIncidentParams(incidentId, acceptIncidentParamsSuccess)
      )
    );
  };

  const acceptIncidentParamsSuccess = (res) => {
    if (res) {
      history.goBack();
    }
  };

  //---------------------------------------------------------------------------
  //------[ DECLINE INCIDENT ]-------------------------------------------------
  //---------------------------------------------------------------------------

  const declineIncident = () => {
    const optionValues = {
      id: Math.random(),
      cssClass: "modal-header__danger",
      dialogClassName:"decline-incidence",
      title: t("DeclineIncidentQuestion"),
    };
    const options = {
      ...optionValues,
      body: (
        <PopUpDeclineIncident
          declineIncident={() => {
            dispatch(
              startRequest(
                declineIncidentParams(incidentId, declineIncidentParamsSuccess)
              )
            );
          }}
          handleButtonCancel={() => dispatch(closeModal())}
        />
      ),
    };

    dispatch(openModal(options));
  };

  const declineIncidentParamsSuccess = (res) => {
    dispatch(closeModal());
    if (res) {
      history.goBack();
    }
  };

  //---------------------------------------------------------------------------
  //------[ RENDERS ]----------------------------------------------------------
  //---------------------------------------------------------------------------

  const renderMedia = (array) => {
    let html = [];
    array.forEach((md, i) => {
      html.push(
        <div className="media-card" key={i}>
          <div
            className="img-wrapper"
            onClick={() => {
              imageBigger(md);
            }}
          >
            <img
              id={"render-file" + Math.random()}
              key={"render-file" + Math.random()}
              className="img-item-popup"
              alt={md ? md.fileName : " "}
              src={
                md.mediaContentType !== "Document"
                  ? "data:image/jpg;base64," + md.documentBody
                  : md.commonDocumentURL
              }
            />
          </div>
          <div className="media-body-wrapper">
            <div className="media-name">{md.fileName}</div>
          </div>
        </div>
      );
    });

    return html;
  };

  const imageBigger = (md) => {
    const optionValues = {
      id: "imageBigger" + Math.random(),
      dataTestId: "imageBigger" + Math.random(),
      cssClass: "modal-header__add",
      title: t("Multimedia"),
    };

    const options = {
      ...optionValues,
      body: (
        <PopUpImage
          action={"image"}
          optionValues={optionValues}
          md={md}
          incidentId={incidentId}
        />
      ),
    };

    dispatch(openModal(options));
  };

  const renderInfo = () => {
    return (
      <React.Fragment>
        <p className="incident-title">{t("IncidentTitle")}</p>
        <p className="incident-subtitle">{t(incident.title)}</p>
        <p className="incident-title">{t("IncidentType")}</p>
        <p className="incident-subtitle">{t(incident.incidentTypeValue)}</p>
        <p className="incident-title">{t("Location")}</p>
        <p className="incident-subtitle">{incident.locationValue}</p>
        <p className="incident-title">{t("Description")}</p>
        <p className="incident-subtitle">{incident.description}</p>
        <p className="incident-title">{t("Deadline")}</p>
        {incident.deadLineDate ? (
          <p className="incident-subtitle">
            {parseDate(incident.deadLineDate)}
          </p>
        ) : (
          <p className="incident-subtitle">-</p>
        )}
      </React.Fragment>
    );
  };

  const renderStatus = () => {
    return (
      <div className="status-wrapper">
        <div
          className={
            incident.statusCodeValue === "InProgress"
              ? "status progress"
              : incident.statusCodeValue === "Resolved"
              ? "status resolved"
              : "status pending"
          }
        >
          {t(incident.statusCodeValue)}
        </div>
        <div className="subStatus">{t(incident.clientStatusCodeValue)}</div>
      </div>
    );
  };

  const renderContact = () => {
    return (
      <React.Fragment>
        <h2 className="incident-title">{t("Contact")}</h2>
        <p className="incident-subtitle">{incident.phoneContact}</p>
        <p className="incident-subtitle">{incident.emailContact}</p>
        <p className="incident-subtitle">{t(incident.contactScheduleValue)}</p>
        <p className="incident-subtitle">{t(incident.ticketNumber)}</p>
      </React.Fragment>
    );
  };

  return (
    <div className="tab-general">
      <div className="incident-wrapper">
        {isMobile ? (
          <React.Fragment>
            <div className="col-sm-3">{renderStatus()}</div>
            <div className="col-sm-9">{renderInfo()}</div>
            <div className="col-lg-12">{renderContact()}</div>
          </React.Fragment>
        ) : isTabletOrMobile ? (
          <React.Fragment>
            <div className="row">
              <div className="col-sm-9">{renderInfo()}</div>
              <div className="col-sm-3">{renderStatus()}</div>
            </div>
            <div className="col-lg-12">{renderContact()}</div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div className="col-lg-3">{renderInfo()}</div>
            <div className="col-lg-3">{renderContact()}</div>
            <div className="right col-lg-3">{renderStatus()}</div>
          </React.Fragment>
        )}
      </div>
      {incident.statusCodeValue !== "Pending" && (
        <>
          <h2 className="title-uploader">
            {t("Images") + " (" + media.length + ")"}
          </h2>
          <h3 className="subtitle-uploader">.jpg, .jpeg, .png, .bmp, .svg, .tiff</h3>
          <div className="row">
            {incident.statusCodeValue === "InProgress" && (
              <div className="col-lg-6">
                <div className="uploader">
                  <Uploader
                    id={"imagesUploader"}
                    isDisabled={false}
                    allowedExtensions={".jpg, .jpeg, .png, .bmp, .svg, .tiff"}
                    setUploader={setImagesUploader}
                  />
                </div>
              </div>
            )}
            <div className="col-lg-6 ">
              <div className="media-wrapper">
                {renderMedia(mediaWithBinary)}
              </div>
            </div>
          </div>

          <h2 className="title-uploader">
            {t("Invoices") + " (" + invoices.length + ")"}
          </h2>
          <h3 className="subtitle-uploader">.pdf, .doc, .docx</h3>
          <div className="row">
              <div className="col-lg-6">
                <div className="uploader">
                  <Uploader
                    id={"invoicesUploader"}
                    isDisabled={false}
                    allowedExtensions={".pdf, .doc, .docx"}
                    setUploader={setInvoicesUploader}
                  />
                </div>
              </div>
            <div className="col-lg-6 ">
              <div className="media-wrapper">
                {renderMedia(invoicesWithBinary)}
              </div>
            </div>
          </div>

          <h2 className="title-uploader">
            {t("Budgets") + " (" + budgets.length + ")"}
          </h2>
          <h3 className="subtitle-uploader">.pdf, .doc, .docx</h3>
          <div className="row">
              <div className="col-lg-6">
                <div className="uploader">
                  <Uploader
                    id={"budgetsUploader"}
                    isDisabled={false}
                    allowedExtensions={".pdf, .doc, .docx"}
                    setUploader={setBudgetsUploader}
                  />
                </div>
              </div>

            <div className="col-lg-6 ">
              <div className="media-wrapper">
                {renderMedia(budgetsWithBinary)}
              </div>
            </div>
          </div>

          <h2 className="title-uploader">{t("Amount")}</h2>
          <div className="row">
            <div className="col-lg-6">
              <div className="import">
                <Input
                  inputLabel={""}
                  inputMaxLength={1200}
                  inputID="message"
                  isDisabled={!(incident.statusCodeValue == "InProgress" || incident.statusCodeValue == "Resolved")}
                  onHandleChange={(e) => {
                    let val = e.currentTarget.value
                      .replace(" €", "")
                      .replace(/[^0-9.,]/g, "");
                    setAmount(val);
                  }}
                  moveCursor={true}
                  value={amount > 0 ? amount + " €" : "0 €"}
                  isRequired={false}
                />
              </div>
            </div>
          </div>
        </>
      )}

      {incident.statusCodeValue === "Pending" && (
        <React.Fragment>
          <div className="btn-end-multiple">
            <Button
              dataTestID="btnSaveSpace"
              btnText={t("DeclineIncident")}
              btnType="button"
              btnClass="btn-base_danger"
              isDisabled={false}
              onHandleBtnClick={() => declineIncident()}
            />
            <Button
              dataTestID="btnSaveSpace"
              btnText={t("AcceptIncident")}
              btnType="button"
              btnClass="btn-base_normal"
              isDisabled={false}
              onHandleBtnClick={() => acceptIncident()}
            />
          </div>
        </React.Fragment>
      )}

      {(incident.statusCodeValue === "InProgress" || incident.statusCodeValue === "Resolved" )&& (
        <React.Fragment>
          <div className="btn-end-multiple">
            <Button
              dataTestID="btnSaveSpace"
              btnText={t("Send")}
              btnType="button"
              btnClass="btn-base_normal"
              isDisabled={false}
              onHandleBtnClick={() => postIncidentProviderMedia()}
            />
            {incident.statusCodeValue !== "Resolved" &&(
            <Button
              dataTestID="btnSaveSpace"
              btnText={t("FinishWork")}
              btnType="button"
              btnClass="btn-base_normal"
              isDisabled={false}
              onHandleBtnClick={() => finishIncident()}
            />
            )}
          </div>
        </React.Fragment>
      )}
    </div>
  );
};
export default TabGeneral;
