import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Link from '@material-ui/core/Link';
import { postResetPasswordParams } from './ResetPasswordCalls/ResetPasswordCalls';
import { showFixedLabel } from '../../../actions/fixedLabel';
import { startRequest } from '../../../actions/apiCall';
import { ROUTE } from '../../../common/constants/routes';
import FixedLabel from '../../../shared/FixedLabel';
import { Input, Button } from '../../../components/FormComponents/index';

import './ResetPassword.scss';

const ResetPassword = () => {
	const history = useHistory();
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const [ error, setError ] = useState(false);
	const [ errorResponse, setErrorResponse ] = useState({});
	const [ token, setToken ] = useState('');
	const [ email, setEmail ] = useState('');
	const [ password, setPassword ] = useState('');
	const [ confirmPassword, setConfirmPassword ] = useState('');
	const [ passwordHidden, setPasswordHidden ] = useState(true);
	const [ confirmPasswordHidden, setConfirmPasswordHidden ] = useState(true);

	useEffect(() => {
		const search = history.location.search;
		const searchFirstSplit = search.split('?token=')[1];
		const params = searchFirstSplit.split('&email=');
		setToken(params[0]);
		setEmail(params[1]);
	}, []);

	useEffect(() => {
		document.title = t('ResetPassword');
	});

	const submitResetPassword = (e) => {
		const data = {
			email,
			token,
			confirmPassword,
			password
		};
		if (password === confirmPassword) {
			dispatch(
				startRequest(
					postResetPasswordParams(
						data,
						() => {
							dispatch(showFixedLabel(t('PasswordSaved'), 'success'));
							history.push(ROUTE.ROOT);
						},
						(error) => {
							setError(true);
							setErrorResponse(error);
						}
					)
				)
			);
		} else {
			setError(true);
			setErrorResponse({
				data: { errors: [ { errorMessageKey: 'PasswordsNotMatch' } ] }
			});
		}
	};

	const testPassword = (password) => {
		if (
			password.match(/[a-z]/g) &&
			password.match(/[A-Z]/g) &&
			password.match(/[0-9]/g) &&
			password.match(/[^a-zA-Z\d]/g) &&
			password.length >= 8
		) {
			return true;
		} else {
			return false;
		}
	};

	const handleKeyPress = (event) => {
		if (event.key === 'Enter') {
			event.preventDefault();
			if (password !== '' && confirmPassword !== '') {
				submitResetPassword(event);
			}
		}
	};

	return (
		<div
			className="resetpassword"
			style={{
				background: `url('https://images.pexels.com/photos/417173/pexels-photo-417173.jpeg')`,
				backgroundPosition: 'center',
				backgroundRepeat: 'no-repeat',
				backgroundSize: 'cover'
			}}
		>
			<div className="wrapper">
				<div className="white-wrapper">
					<div className="img-wrapper">
						<img src="./residentialmanagement.png" alt="logo" />
					</div>
					<FixedLabel parentType="general" />
					<div className="resetpassword-form">
						<form
							className="form-signin"
							name="resetPasswordForm"
							title={t('ResetPassword')}
							aria-label={t('ResetPassword')}
							// onSubmit={submitResetPassword}
						>
							<p className="resetpassword-title">{t('ResetPassword')}</p>
							<div className="form-wrapper">
								<Input
									dataTestID="txtPassword"
									inputName="txtPassword"
									inputType={passwordHidden ? 'password' : 'text'}
									inputLabel={t('Password')}
									inputID="user-loginPassword"
									isDisabled={false}
									onHandleChange={(e) => setPassword(e.currentTarget.value)}
									value={password}
									icon={passwordHidden ? 'visibility' : 'visibility_off'}
									isRequired={true}
									onHandleKeyPress={handleKeyPress}
									onHandleClick={() => setPasswordHidden(!passwordHidden)}
								/>
							</div>
							<div className="form-wrapper">
								<Input
									dataTestID="txtConfirmPassword"
									inputName="txtConfirmPassword"
									inputType={confirmPasswordHidden ? 'password' : 'text'}
									inputLabel={t('ConfirmPassword')}
									inputID="user-loginPassword"
									isDisabled={false}
									onHandleChange={(e) => setConfirmPassword(e.currentTarget.value)}
									value={confirmPassword}
									onHandleKeyPress={handleKeyPress}
									icon={confirmPasswordHidden ? 'visibility' : 'visibility_off'}
									isRequired={true}
									onHandleClick={() => setConfirmPasswordHidden(!confirmPasswordHidden)}
								/>
							</div>
							<div className="link-forgotPass">
								<Link
									onClick={(e) => {
										e.preventDefault();
										history.push(ROUTE.LOGIN);
									}}
								>
									<span>{t('DoYouHaveAnAccount')}</span>
								</Link>
							</div>
							<div className="button-container">
								<Button
									btnText={t('Send')}
									btnType="button"
									onHandleBtnClick={submitResetPassword}
									isDisabled={password === '' || confirmPassword === ''}
									btnClass="btn-base_normal"
									dataTestID="btnResetPasswordButton"
								/>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ResetPassword;
