import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Input, Button } from '@backoffice/components/FormComponents/index';
import {
	getServicesTypeParams,
	getServiceDetailByIdParams,
	postServiceParams
} from '../../BuildingDetailCalls/BuildingDetailCalls';
import { startRequest } from '../../../../../../actions/apiCall';
import { useHistory } from 'react-router-dom';
import { ROUTE } from '../../../../../../common/constants/routes';
import { showFixedLabel } from '../../../../../../actions/fixedLabel';
import { Combo, Textarea } from '../../../../../../components/FormComponents';
import { openModal, closeModal } from '../../../../../../actions/popup';
import Uploader from '../../../../../../shared/Uploader/Uploader';
import FilePreview from '../../../../../../shared/FilePreview/FilePreview';
import PopUpImage from './PopUpImage/PopUpImage';
import './PopUpService.scss';

const PopUpService = ({ serviceId, action, buildingId, handleSubmit }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const history = useHistory();
	const [ media, setMedia ] = useState([]);
	const [ name, setName ] = useState('');
	const [ phoneNumber, setPhoneNumber ] = useState('');
	const [ email, setEmail ] = useState('');
	const [ address, setAddress ] = useState('');
	const [ url, setUrl ] = useState('');
	const [ description, setDescription ] = useState('');
	const [ uploader, setUploader ] = useState('');
	const [ serviceType, setServiceType ] = useState('');
	const [ serviceTypeOptions, setServiceTypeOptions ] = useState([]);

	useEffect(() => {
		getServicesType();
	}, []);

	useEffect(
		() => {
			if (serviceTypeOptions.length > 0 && serviceId !== '') {
				getServiceById();
			}
		},
		[ serviceTypeOptions ]
	);

	const getServicesType = () => {
		dispatch(startRequest(getServicesTypeParams(getServicesTypeParamsSuccess)));
	};

	const getServicesTypeParamsSuccess = (response) => {
		setServiceTypeOptions(response);
	};

	const getServiceById = () => {
		dispatch(startRequest(getServiceDetailByIdParams(buildingId, serviceId, getServiceDetailByIdSuccess)));
	};

	const getServiceDetailByIdSuccess = (response) => {
		setName(response.name);
		setPhoneNumber(response.phoneNumber);
		setEmail(response.email);
		setAddress(response.address);
		setUrl(response.url);
		setDescription(response.description);
		setMedia(response.media);
		setServiceType({
			id: response.serviceTypeId,
			name: response.serviceTypeName
		});
	};

	const getMedia = () => {
		let serviceMedia = [];
		uploader.filesData.forEach((file) => {
			serviceMedia.push({
				File: file.rawFile
			});
		});
		return serviceMedia;
	};

	const getServiceForm = () => {
		return {
			serviceId: serviceId,
			buildingId: buildingId,
			name: name,
			media: media,
			phoneNumber: phoneNumber,
			email: email,
			address: address,
			url: url,
			description: description,
			serviceTypeId: serviceType.id,
			Files: getMedia()
		};
	};

	const validateForm = () => {
		let formOK = true;
		if (name === '' || serviceType === '' || phoneNumber === '' || address === '' || description === '') {
			formOK = false;
		}
		return !formOK;
	};

	const postNewService = () => {
		let newService = getServiceForm();
		dispatch(startRequest(postServiceParams(newService, postServiceParamsSuccess)));
	};

	const postServiceParamsSuccess = (response) => {
		dispatch(closeModal());
		dispatch(showFixedLabel('ServiceAddSuccess', 'success'));
		handleSubmit();
	};

	const editService = () => {
		let newService = getServiceForm();
		dispatch(startRequest(postServiceParams(newService, editServiceSuccess)));
	};

	const editServiceSuccess = (response) => {
		dispatch(closeModal());
		dispatch(showFixedLabel('EditServiceSuccess', 'success'));
		handleSubmit();
	};

	const renderMedia = () => {
		let html = [];
		media.forEach((md, i) => {
			html.push(
				<div className="media-card" key={i}>
					<div className="img-wrapper" onClick={() => imageBigger(md)}>
						<FilePreview file={''} url={md.mediaThumbnailUrl} fileType={'Image'} />
					</div>
					<div className="media-body-wrapper">
						<div className="media-name">{md.originalFileName}</div>
					</div>
				</div>
			);
		});

		return html;
	};

	const imageBigger = (md) => {
		const optionValues = {
			id: 'imageBigger' + Math.random(),
			dataTestId: 'imageBigger' + Math.random(),
			cssClass: 'modal-header__add',
			title: t('Multimedia')
		};

		const options = {
			...optionValues,
			body: <PopUpImage action={'image'} optionValues={optionValues} md={md} />
		};

		dispatch(openModal(options));
	};

	return (
		<div className="service-popup">
			<div className="service-form">
				<div className="row">
					<div className="col-lg-3">
						<Input
							dataTestID="txtName"
							inputName="txtName"
							inputType="text"
							inputLabel={t('Name')}
							inputID="service-name"
							isDisabled={false}
							onHandleChange={(e) => {
								let val = e.currentTarget.value;
								setName(val);
							}}
							value={name}
							isRequired={true}
						/>
					</div>
					<div className="col-lg-3">
						<Combo
							id="cmbServiceType"
							dataTestID="cmbServiceType"
							comboLabel={t('ServiceType')}
							inputValue={serviceType}
							comboPlaceholder={t('Select') + ' ' + t('Service') + '...'}
							defaultInputValue=""
							isRequired={true}
							isSearchable={false}
							getOptionValue={(option) => option['id']}
							getOptionLabel={(option) => t(option['name'])}
							options={serviceTypeOptions}
							isMultiple={false}
							handleChange={(e) => {
								setServiceType(e);
							}}
						/>
					</div>
					<div className="col-lg-3">
						<Input
							dataTestID="txtPhone"
							inputName="txtPhone"
							inputType="text"
							inputLabel={t('Phone')}
							inputID="service-phone"
							isDisabled={false}
							onHandleChange={(e) => {
								let val = e.currentTarget.value;
								setPhoneNumber(val);
							}}
							value={phoneNumber}
							isRequired={true}
						/>
					</div>
					<div className="col-lg-3">
						<Input
							dataTestID="txtEmail"
							inputName="txtEmail"
							inputType="text"
							inputLabel={t('Email')}
							inputID="service-email"
							isDisabled={false}
							onHandleChange={(e) => {
								let val = e.currentTarget.value;
								setEmail(val);
							}}
							value={email}
							isRequired={false}
						/>
					</div>
				</div>
				<div className="row">
					<div className="col-lg-6">
						<Input
							dataTestID="txtAddress"
							inputName="txtAddress"
							inputType="text"
							inputLabel={t('Address')}
							inputID="service-address"
							isDisabled={false}
							onHandleChange={(e) => {
								let val = e.currentTarget.value;
								setAddress(val);
							}}
							value={address}
							isRequired={true}
						/>
					</div>
					<div className="col-lg-6">
						<Input
							dataTestID="txtUrl"
							inputName="txtUrl"
							inputType="text"
							inputLabel={t('URL')}
							inputID="service-url"
							isDisabled={false}
							onHandleChange={(e) => {
								let val = e.currentTarget.value;
								setUrl(val);
							}}
							value={url}
							isRequired={false}
						/>
					</div>
				</div>
				<div className="row">
					<div className="col-lg-6">
						<Textarea
							dataTestID="txtObservations"
							inputName="txtObservations"
							inputLabel={t('Observations')}
							inputType="text"
							inputValue={description}
							isRequired={true}
							isDisabled={false}
							inputOutline={true}
							onHandleChange={(e) => {
								setDescription(e.currentTarget.value);
							}}
						/>
					</div>
				</div>
				<div className="row">
					<div className="col-lg-6">
						<div className="uploader-wrapper">
							<Uploader isDisabled={false} allowedExtensions={'.jpg,.jpeg,.png'} setUploader={setUploader} />
						</div>
					</div>
					<div className="col-lg-6">
						<div className="media-wrapper">{renderMedia()}</div>
					</div>
				</div>
			</div>
			<div className="btn-end">
				<Button
					dataTestID="btnSaveService"
					btnText={t('SaveChanges')}
					btnType="button"
					btnClass="btn-base_normal"
					isDisabled={validateForm()}
					onHandleBtnClick={() => {
						if (action === 'add') {
							postNewService();
						} else if (action === 'edit') {
							editService();
						}
					}}
				/>
			</div>
		</div>
	);
};

export default PopUpService;
