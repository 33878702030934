import React, { useEffect, useState, useRef } from "react";
import { startRequest } from "@backoffice/actions/apiCall";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import {
  getIncidentsParams,
  getBuildingsParams,
  getIncidentStatusParams,
  getFullIncidentsParams,
  getIncidentTypesParams,
  getIncidentPriorityParams,
} from "./IncidentsProviderCalls/IncidentsProviderCalls";
import { Input, Combo } from "@backoffice/components/FormComponents/index";
import { parseDate, parseDateTime } from "../../../../common/utils/parseUtils";
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  Inject,
  Page,
  Sort,
  ExcelExport,
  Group,
  Filter,
  Resize,
} from "@syncfusion/ej2-react-grids";
import ResponsiveGridWrapper from "../../../../shared/ResponsiveGridWrapper/ResponsiveGridWrapper";
import { ROUTE } from "../../../../common/constants/routes";
import "./IncidentsProvider.scss";

const IncidentsProvider = () => {
  const pageSettings = { pageSize: 10 };
  const grid = useRef(null);
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [incidents, setIncidents] = useState([]);
  const [incidentsInProgress, setIncidentsInProgress] = useState([]);
  const [incidentsPending, setIncidentsPending] = useState([]);
  const [incidentsResolved, setIncidentsResolved] = useState([]);
  const [search, setSearch] = useState("");
  const [building, setBuilding] = useState("");
  const [buildings, setBuildings] = useState([]);
  const [status, setStatus] = useState("");
  const [statusOptions, setStatusOptions] = useState([]);
  const [priority, setPriority] = useState("");
  const [priorityOptions, setPriorityOptions] = useState([]);
  const [incidentType, setIncidentType] = useState("");
  const [incidentTypeOptions, setIncidentTypeOptions] = useState([]);
  const [showTable, setShowTable] = useState("");
  const handleTableClose = () => setShowTable(false);
  const handleTableOpen = () => setShowTable(true);

  useEffect(() => {
    getBuildings();
    getIncidentTypes();
    getIncidentPriority();
    getIncidentStatus();
  }, []);

  useEffect(() => {
    if (showTable === true) {
      getFullIncidents();
    } else {
      getIncidents();
    }
  }, [search, building, status, incidentType, priority, showTable]);

  const getIncidents = () => {
    dispatch(
      startRequest(
        getIncidentsParams(
          {
            filter: {
              Text: search,
              BuildingId: building.id,
              StatusId: status.value,
              CaseTypeId: incidentType.value,
              PriorityId: priority.value,
            },
          },
          getIncidentsParamsSuccess
        )
      )
    );
  };

  const getIncidentsParamsSuccess = (response) => {
    setIncidentsPending(response.pending);
    setIncidentsInProgress(response.inProgress);
    setIncidentsResolved(response.resolved);
  };

  const getFullIncidents = () => {
    dispatch(
      startRequest(
        getFullIncidentsParams(
          {
            filter: {
              Text: search,
              BuildingId: building.id,
              StatusId: status.value,
              CaseTypeId: incidentType.value,
              PriorityId: priority.value,
            },
          },
          getFullIncidentsParamsSuccess
        )
      )
    );
  };

  const getFullIncidentsParamsSuccess = (response) => {
    setIncidents(response);
  };

  const getBuildings = () => {
    dispatch(startRequest(getBuildingsParams(getBuildingsSuccess)));
  };

  const getBuildingsSuccess = (response) => {
    response.unshift({ id: "0", name: "All" });
    setBuildings(response);
  };

  const getIncidentStatus = () => {
    dispatch(
      startRequest(getIncidentStatusParams(getIncidentStatusParamsSuccess))
    );
  };

  const getIncidentStatusParamsSuccess = (response) => {
    response.unshift({ id: "0", name: "All" });
    setStatusOptions(response);
  };

  const getIncidentTypes = () => {
    dispatch(
      startRequest(getIncidentTypesParams(getIncidentTypesParamsSuccess))
    );
  };

  const getIncidentTypesParamsSuccess = (response) => {
    response.unshift({ id: "0", name: "All" });
    setIncidentTypeOptions(response);
  };

  const getIncidentPriority = () => {
    dispatch(
      startRequest(getIncidentPriorityParams(getIncidentPriorityParamsSuccess))
    );
  };

  const getIncidentPriorityParamsSuccess = (response) => {
    response.unshift({ id: "0", name: "All" });
    setPriorityOptions(response);
  };
  const priorityTemplate = (rowData) => {
    return (
      <div className="priorityTemplate">
        <span
          className={
            "material-icons-round " + rowData.priorityValue.toLowerCase()
          }
        >
          label_important
        </span>

        <span
          className={"priority-tag"}
          data-testid={`priorityCell_${rowData.index}`}
        >
          {rowData.priorityValue}
        </span>
      </div>
    );
  };
  const statusTemplate = (rowData) => {
    return (
      <div className="statusTemplate">
        <span className={`status${rowData.statusCodeValue}`}>
          {t(rowData.statusCodeValue)}
        </span>
      </div>
    );
  };
  const subStatusTemplate = (rowData) => {
    return (
      <div className="subStatusTemplate">
        <span className={`status${rowData.clientStatusCodeValue}`}>
          {t(rowData.clientStatusCodeValue)}
        </span>
      </div>
    );
  };

  const actionsTemplate = (rowData) => {
    return (
      <div className="action-template">
        <span
          className="material-icons-round"
          data-testid={`btnEditStandar_${rowData.index}`}
          id={`btnEditStandar_${rowData.index}`}
          onClick={() =>
            history.push({
              pathname: ROUTE.INCIDENTSPROVIDERDETAIL.replace(
                ":id",
                rowData.id
              ),
            })
          }
          type="button"
        >
          mode_edit
        </span>
      </div>
    );
  };

  const renderIncidents = (incidents) => {
    let html = [];
    if (incidents && incidents.length > 0) {
      incidents.forEach((incident, i) => {
        html.push(
          <Link
            key={i + "-incidents"}
            className="card"
            to={ROUTE.INCIDENTSPROVIDERDETAIL.replace(":id", incident.id)}
          >
            <div className="card-wrapper incident">
              <div className="body-wrapper">
                {incident.title != null ? (
                  <h3 className="title-card">{incident.title}</h3>
                ) : (
                  ""
                )}
                {incident.shortDescription != null ? (
                  <p className="description-card">
                    {incident.shortDescription}
                  </p>
                ) : (
                  ""
                )}
                {incident.incidentTypeValue != null ? (
                  <p className="incident-type-card">
                    {" "}
                    {t(incident.incidentTypeValue)}
                  </p>
                ) : (
                  ""
                )}
                {incident.buildingValue != null ? (
                  <p className="building-incident-card">
                    {" "}
                    {incident.buildingValue}
                  </p>
                ) : (
                  ""
                )}
              </div>
              <div className="footer-wrapper">
                <p className="ticket-number">
                  {incident.ticketNumber}
                </p>
                {incident.creationDate != null ? (
                  <p className="date-card">
                    {t("OpenOn") +
                      " " +
                      parseDateTime(incident.creationDate, true)}
                  </p>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="content-card-right">
              <div className="status-wrapper">
                <div
                  className={
                    incident.statusCodeValue === "InProgress"
                      ? "status progress"
                      : incident.statusCodeValue === "Resolved"
                      ? "status resolved"
                      : "status pending"
                  }
                >
                  {t(incident.statusCodeValue)}
                </div>
                <div className="subStatus">
                  {t(incident.clientStatusCodeValue)}
                </div>
                <p className={incident.priorityValue === "IncidentPriorityTypeHigh" ? "high-priority" : incident.priorityValue === "IncidentPriorityTypeNormal" ? "normal-priority" : "low-priority"}>
                  <span
                    className={"material-icons-round " + incident.priorityValue}
                  >
                    label_important
                  </span>{" "}
                  {t("Priority")} {t(incident.priorityValue)}
                </p>

                {incident.deadLineDate && (
                  <p className="priority-incident-card-deadline">
                    {t("Deadline")} {parseDate(incident.deadLineDate, true)}
                  </p>
                )}
              </div>
            </div>
          </Link>
        );
      });
    } else {
      html.push(
        <p key={"empty-category-progress"} className="empty-category">
          {t("EmptyIncidentsStatus")}
        </p>
      );
    }
    return html;
  };

  // let searchBy = t('Search') + ' ' + t('by') + ' ' + t('Title') + ', ' + t('ShortDescription');
  let searchBy = t("Search") + " " + t("by") + " " + t("WordMin");
  let incidentsPendingCount = incidentsPending ? incidentsPending.length : 0;
  let incidentsInProgressCount = incidentsInProgress
    ? incidentsInProgress.length
    : 0;
  let incidentsResolvedCount = incidentsResolved ? incidentsResolved.length : 0;

  return (
    <div className="incidents-provider">
      <h2 className="title">{t("IncidentProvider")}</h2>
      <div className="header-container">
        <div className="col-lg-11">
          <div className="filters-wrapper">
            <div
              className="col-xl-4 content-search"
              data-bs-toggle="tooltip"
              title={searchBy}
            >
              <Input
                dataTestID="txtSearch"
                inputName="txtSearch"
                clearInput={true}
                inputPlaceholder={searchBy}
                inputType="text"
                value={search}
                inputOutline={true}
                icon={"search"}
                iconLeft={true}
                isRequired={false}
                onHandleChange={(e) => setSearch(e.target.value)}
              />
            </div>
            <div className="content-filters">
              <div className="col-xl-3">
                <Combo
                  id="cmbBuilding"
                  dataTestID="cmbBuilding"
                  comboLabel={t("Building")}
                  inputValue={building}
                  comboPlaceholder={t("Select") + " " + t("Building") + "..."}
                  defaultInputValue=""
                  isSearchable={false}
                  getOptionValue={(option) => option["id"]}
                  getOptionLabel={(option) => t(option["name"])}
                  options={buildings}
                  isMultiple={false}
                  handleChange={(e) => {
                    setBuilding(e);
                  }}
                />
              </div>

              <div className="col-xl-3">
                <Combo
                  id="cmbStatus"
                  dataTestID="cmbStatus"
                  comboLabel={t("Status")}
                  inputValue={status}
                  comboPlaceholder={t("Select") + " " + t("Status") + "..."}
                  defaultInputValue=""
                  isSearchable={false}
                  getOptionValue={(option) => option["id"]}
                  getOptionLabel={(option) => t(option["name"])}
                  options={statusOptions}
                  isMultiple={false}
                  handleChange={(e) => {
                    setStatus(e);
                  }}
                />
              </div>
              <div className="col-xl-3">
                <Combo
                  id="cmbPriority"
                  dataTestID="cmbPriority"
                  comboLabel={t("Priority")}
                  inputValue={priority}
                  comboPlaceholder={t("Select") + " " + t("Priority") + "..."}
                  defaultInputValue=""
                  isSearchable={false}
                  getOptionValue={(option) => option["id"]}
                  getOptionLabel={(option) => t(option["name"])}
                  options={priorityOptions}
                  isMultiple={false}
                  handleChange={(e) => {
                    setPriority(e);
                  }}
                />
              </div>
              <div className="col-xl-3">
                <Combo
                  id="cmbIncidentType"
                  dataTestID="cmbIncidentType"
                  comboLabel={t("IncidentType")}
                  inputValue={incidentType}
                  comboPlaceholder={
                    t("Select") + " " + t("IncidentType") + "..."
                  }
                  defaultInputValue=""
                  isSearchable={false}
                  getOptionValue={(option) => option["id"]}
                  getOptionLabel={(option) => t(option["name"])}
                  options={incidentTypeOptions}
                  isMultiple={false}
                  handleChange={(e) => {
                    setIncidentType(e);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="view-mode-row">
          {/* <p className="view-text">{t("View")}</p> */}
          <div className="icons">
            <span
              className={"material-icons-round " + (showTable ? "" : "active")}
              onClick={handleTableClose}
            >
              view_column
            </span>
            <span
              className={"material-icons " + (showTable ? "active" : "")}
              onClick={handleTableOpen}
            >
              list
            </span>
          </div>
        </div>
      </div>

      {showTable ? (
        <div className="incidents-provider-table">
          <ResponsiveGridWrapper>
            <GridComponent
              className="gridTable"
              dataSource={incidents.map((x) => {
                return {
                  ...x,
                  incidentTypeValue: t(x.incidentTypeValue),
                  priorityValue: t(x.priorityValue),
                };
              })}
              width="100%"
              toolbar={false}
              ref={grid}
              allowExcelExport={true}
              allowPaging={true}
              enableAdaptiveUI={true}
              allowSorting={true}
              rowRenderingMode={"Vertical"}
              pageSettings={pageSettings}
              rowSelected={(x) => {
                history.push({
                  pathname: ROUTE.INCIDENTSPROVIDERDETAIL.replace(
                    ":id",
                    x.data.id
                  ),
                });
              }}
            >
              <ColumnsDirective>
                <ColumnDirective field="id" width="0" visible={false} />
                <ColumnDirective
                field="ticketNumber"
                headerText={t("TicketNumber")}
                textAlign="Left"/>
                <ColumnDirective
                  field="title"
                  headerText={t("IncidentName")}
                  textAlign="Left"
                />
                <ColumnDirective
                  field="incidentTypeValue"
                  headerText={t("Type")}
                  textAlign="Left"
                />
                <ColumnDirective
                  field="buildingValue"
                  headerText={t("Building")}
                  textAlign="Left"
                />
                <ColumnDirective
                  field="creationDate"
                  headerText={t("CreationDate")}
                  textAlign="Left"
                  valueAccessor={(field, data, column) => {
                    return parseDate(data.creationDate, true);
                  }}
                />
                <ColumnDirective
                  field="limitDate"
                  headerText={t("Deadline")}
                  textAlign="Left"
                  valueAccessor={(field, data, column) => {
                    if (data.deadLineDate) {
                      return parseDate(data.deadLineDate, true);
                    } else {
                      return "-";
                    }
                  }}
                />
                <ColumnDirective
                  field="priorityValue"
                  headerText={t("Priority")}
                  template={priorityTemplate}
                  textAlign="Left"
                />
                <ColumnDirective
                  field="statusCodeValue"
                  headerText={t("Status")}
                  template={statusTemplate}
                  textAlign="Left"
                  width="150"
                  valueAccessor={(field, data, column) => {
                    return t(data["statusCodeValue"]);
                  }}
                />

                <ColumnDirective
                  field="clientStatusCodeValue"
                  headerText={t("SubStatus")}
                  template={subStatusTemplate}
                />
                <ColumnDirective
                  field="actions"
                  headerText={""}
                  template={actionsTemplate}
                  textAlign="Right"
                  width="50"
                />
              </ColumnsDirective>
              <Inject
                services={[ExcelExport, Page, Sort, Filter, Group, Resize]}
              />
            </GridComponent>
          </ResponsiveGridWrapper>
        </div>
      ) : (
        <div className="row">
          <div className="col-xl-4 pending-list">
            <h3 className="title-status">
              {t("IncidentsPendings") + " (" + incidentsPendingCount + ")"}
            </h3>
            <div className="card-list-wrapper">
              {renderIncidents(incidentsPending)}
            </div>
          </div>
          <div className="col-xl-4 inprogress-list">
            <h3 className="title-status">
              {t("IncidentsInProgress") + " (" + incidentsInProgressCount + ")"}
            </h3>
            <div className="card-list-wrapper border-list">
              {renderIncidents(incidentsInProgress)}
            </div>
          </div>
          <div className="col-xl-4 resolved-list">
            <h3 className="title-status">
              {t("IncidentsResolved") + " (" + incidentsResolvedCount + ")"}
            </h3>
            <div className="card-list-wrapper">
              {renderIncidents(incidentsResolved)}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default IncidentsProvider;
