import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { postConfirmMailParams } from './ConfirmMailCalls';
import { showFixedLabel } from '../../../actions/fixedLabel';
import { startRequest } from '../../../actions/apiCall';
import { ROUTE } from '../../../common/constants/routes';
import FixedLabel from '../../../shared/FixedLabel';
import { Button, Input } from '../../../components/FormComponents';
import './ConfirmMail.scss';

const ConfirmMail = () => {
	const history = useHistory();
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const [ error, setError ] = useState(false);
	const [ errorResponse, setErrorResponse ] = useState({});
	const [ token, setToken ] = useState('');
	const [ password, setPassword ] = useState('');
	const [ confirmPassword, setConfirmPassword ] = useState('');
	const [ passwordHidden, setPasswordHidden ] = useState(true);
	const [ confirmPasswordHidden, setConfirmPasswordHidden ] = useState(true);

	useEffect(() => {
		const search = history.location.search;
		const token = search.split('?token=')[1];
		setToken(token);
		document.title = t('ConfirmPassword');
	}, []);
	
	const handleKeyPress = (event) => {
		if (event.key === 'Enter') {
			event.preventDefault();
			if (password !== '' && confirmPassword !== '') {
				submitConfirmPassword(event);
			}
		}
	};

	const submitConfirmPassword = (e) => {
		const data = {
			token,
			confirmPassword,
			password
		};
		if (password === confirmPassword) {
			dispatch(
				startRequest(
					postConfirmMailParams(
						data,
						() => {
							dispatch(showFixedLabel(t('PasswordCreated'), 'success'));
							history.push(ROUTE.ROOT);
						},
						(error) => {
							setError(true);
							setErrorResponse(error);
						}
					)
				)
			);
		} else {
			setError(true);
			setErrorResponse({
				data: { errors: [ { errorMessageKey: 'PasswordsNotMatch' } ] }
			});
		}
	};

	return (
		<div
			className="confirmemail"
			lang="es"
			style={{
				background: `url('https://images.pexels.com/photos/417173/pexels-photo-417173.jpeg')`,
				backgroundPosition: 'center',
				backgroundRepeat: 'no-repeat',
				backgroundSize: 'cover'
			}}
		>
			<div className="wrapper">
				<div className="white-wrapper">
					<div className="img-wrapper">
						<img src="./residentialmanagement.png" alt="logo" />
					</div>
					<FixedLabel parentType="general" />
					<div className="confirmmail-form">
						<form
							className="form-signin"
							name="confirmmailForm"
							title={t('ConfirmPassword')}
							aria-label={t('ConfirmPassword')}
							// onSubmit={submitResetPassword}
						>
							<div className="form-wrapper">
								<Input
									dataTestID="txtPassword"
									inputName="txtPassword"
									inputType={passwordHidden ? 'password' : 'text'}
									inputLabel={t('Password')}
									inputID="user-loginPassword"
									isDisabled={false}
									onHandleChange={(e) => setPassword(e.currentTarget.value)}
									value={password}
									icon={passwordHidden ? 'visibility' : 'visibility_off'}
									isRequired={true}
									onHandleKeyPress={handleKeyPress}
									onHandleClick={() => setPasswordHidden(!passwordHidden)}
								/>
							</div>
							<div className="form-wrapper">
								<Input
									dataTestID="txtConfirmPassword"
									inputName="txtConfirmPassword"
									inputType={confirmPasswordHidden ? 'password' : 'text'}
									inputLabel={t('ConfirmPassword')}
									inputID="user-loginPassword"
									isDisabled={false}
									onHandleChange={(e) => setConfirmPassword(e.currentTarget.value)}
									value={confirmPassword}
									onHandleKeyPress={handleKeyPress}
									icon={confirmPasswordHidden ? 'visibility' : 'visibility_off'}
									isRequired={true}
									onHandleClick={() => setConfirmPasswordHidden(!confirmPasswordHidden)}
								/>
							</div>
							<div className="button-container">
								<Button
									btnText={t('ConfirmPassword')}
									btnType="button"
									onHandleBtnClick={submitConfirmPassword}
									isDisabled={password === '' || confirmPassword === ''}
									btnClass="btn-base_normal"
									dataTestID="btnCreatePasswordButton"
								/>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ConfirmMail;
