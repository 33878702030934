import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { startRequest } from "../../../../actions/apiCall";
import { showFixedLabel } from "../../../../actions/fixedLabel";
import { ROUTE } from "../../../../common/constants/routes";
import { validateEmail } from "../../../../common/validations/common";
import {
  Button,
  Combo,
  Input,
  Textarea,
} from "../../../../components/FormComponents";
import Uploader from "../../../../shared/Uploader/Uploader";
import "./IncidentsTenantCreate.scss";
import {
  getIncidentScheduleTypesParams,
  postIncidentsTenantParams,
  postMediaParams,
  getIncidentCaseTypesParams,
  getIncidentUbicationTypesParams,
  getTenantCreateIncident,
} from "./IncidentsTenantCreateCalls/IncidentsTenantCreateCalls";

const IncidentsTenantCreate = () => {
  const history = useHistory();
  const { type } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [incidentTitle, setIncidentTitle] = useState("");
  const [incidentDescription, setIncidentDescription] = useState("");
  const [incidentTypeOptions, setIncidentTypeOptions] = useState([]);
  const [incidentType, setIncidentType] = useState("");
  const [incidentLocation, setIncidentLocation] = useState("");
  const [incidentLocationOptions, setIncidentLocationOptions] = useState([]);
  const [tenantPhoneNumber, setTenantPhoneNumber] = useState("");
  const [affectedArea, setAffectedArea] = useState("");
  const [tenantEmail, setTenantEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [contactScheduleOptions, setContactScheduleOptions] = useState([]);
  const [contactSchedule, setContactSchedule] = useState("");
  const [uploader, setUploader] = useState("");

  useEffect(() => {
    getIncidentCaseTypes();
    getLocationTypes();
    getScheduleTypes();
  }, []);

  useEffect(() => {
    if (contactScheduleOptions.length > 0) {
      getUserLogged();
    }
  }, [contactScheduleOptions]);

  const getUserLogged = () => {
    dispatch(
      startRequest(getTenantCreateIncident(getTenantCreateIncidentSuccess))
    );
  };

  const getTenantCreateIncidentSuccess = (response) => {
    setTenantEmail(response.email);
    setTenantPhoneNumber(response.phone);
    let contactScheduleData = contactScheduleOptions.find(
      (x) => x.value == response.contactScheduleDefault
    );
    setContactSchedule(contactScheduleData);
  };
  const getIncidentCaseTypes = () => {
    dispatch(
      startRequest(getIncidentCaseTypesParams(getIncidentCaseTypesSuccess))
    );
  };
  const getIncidentCaseTypesSuccess = (response) => {
    setIncidentTypeOptions(response);
    if (type) {
      switch (type) {
        case "bank":
          setIncidentTitle(t("IncidentCreateBankTitle"));
          setIncidentDescription(t("IncidentCreateBankDescription"));
          break;
        case "email":
          setIncidentTitle(t("IncidentCreateEmailTitle"));
          setIncidentDescription(t("IncidentCreateEmailDescription"));
          break;
        case "phone":
          setIncidentTitle(t("IncidentCreatePhoneTitle"));
          setIncidentDescription(t("IncidentCreatePhoneDescription"));
          break;
        default:
          break;
      }
      setIncidentType({
        id: "c40c0352-8161-4eb1-956c-5e6e271c65fb",
        name: "Administrativa",
        value: "876710000",
      });
    }
  };

  const getLocationTypes = () => {
    dispatch(
      startRequest(
        getIncidentUbicationTypesParams(getIncidentLocationTypesSuccess)
      )
    );
  };

  const getIncidentLocationTypesSuccess = (response) => {
    setIncidentLocationOptions(response);
  };

  const getScheduleTypes = () => {
    dispatch(
      startRequest(
        getIncidentScheduleTypesParams(getIncidentScheduleTypesSuccess)
      )
    );
  };

  const getIncidentScheduleTypesSuccess = (response) => {
    setContactScheduleOptions(response);
  };
  const getIncidentForm = () => {
    return {
      title: incidentTitle,
      description: incidentDescription,
      incidentType: incidentType.value,
      buildingSpaceId: incidentLocation.id,
      location: affectedArea,
      phoneNumber: tenantPhoneNumber,
      email: tenantEmail,
      contactSchedule: contactSchedule.value,
    };
  };

  const validateForm = () => {
    let formOK = true;

    if (type) {
      if (
        incidentTitle === "" ||
        emailError ||
        incidentDescription === "" ||
        incidentType === "" ||
        tenantPhoneNumber === "" ||
        tenantEmail === "" ||
        contactSchedule === ""
      ) {
        formOK = false;
      }
    } else {
      if (
        incidentTitle === "" ||
        emailError ||
        incidentDescription === "" ||
        incidentType === "" ||
        incidentLocation === "" ||
        tenantPhoneNumber === "" ||
        tenantEmail === "" ||
        affectedArea === "" ||
        contactSchedule === ""
      ) {
        formOK = false;
      }
    }
    return !formOK;
  };
  const postTenantIncident = () => {
    let tenantIncident = getIncidentForm();

    dispatch(
      startRequest(
        postIncidentsTenantParams(
          tenantIncident,
          (response) => postIncidentTenantSuccess(response),
          (response) => postIncidentTenantError(response)
        )
      )
    );
  };

  const postIncidentTenantSuccess = (response) => {
    postMedia(response.id);
  };

  const postIncidentTenantError = (response) => {
    dispatch(showFixedLabel(response.data.errors[0].errorMessageKey, "error"));
  };

  const getMedia = (id) => {
    let newMedia = [];
    if (uploader) {
      uploader.filesData.forEach((file) => {
        newMedia.push({
          File: file.rawFile,
        });
      });
      return {
        Id: id,
        Files: newMedia,
      };
    }
  };

  const postMedia = (id) => {
    const mediaData = getMedia(id);
    if (mediaData && mediaData.Files.length > 0) {
      dispatch(startRequest(postMediaParams(mediaData, mediaSuccess)));
    }
    history.push(ROUTE.INCIDENTSTENANT);
    dispatch(showFixedLabel("IncidentAddSuccess", "success"));
  };

  const mediaSuccess = (response) => {
    history.push(ROUTE.INCIDENTSTENANT);
    dispatch(showFixedLabel("IncidentAddSuccess", "success"));
  };

  return (
    <div className="incidents-tenant-create">
      <div className="return">
        <div className="col-lg-2 offsett-lg-10">
          <Link
            className="go-back"
            onClick={() => history.goBack()}
            role="link"
          >
            <span
              className="material-icons-round"
              data-testid={"btn-goback"}
              id={"btn-goback"}
              aria-hidden={true}
            >
              arrow_back
            </span>
            <div className="go-back__text">{t("GoBack")}</div>
          </Link>
        </div>
      </div>
      <div className="incident-tenant-form">
        <h2 className="title">{t("CreateIncident")}</h2>
        <p className="description">{t("Description") + "..."}</p>
        <div className="row">
          <div className="col-lg-3">
            <Input
              dataTestID="txtTitle"
              inputName="txtTitle"
              inputType="text"
              inputLabel={t("IncidentTitle")}
              inputID="incident-title"
              isDisabled={type}
              onHandleChange={(e) => {
                let val = e.currentTarget.value;
                setIncidentTitle(val);
              }}
              value={incidentTitle}
              isRequired={true}
            />
          </div>
          <div className="col-lg-3">
            <Combo
              id="cmbIncidentType"
              dataTestID="cmbIncidentType"
              comboLabel={t("IncidentType")}
              inputValue={incidentType}
              comboPlaceholder={t("Select") + " " + t("IncidentType") + "..."}
              defaultInputValue=""
              isRequired={true}
              isSearchable={false}
              isDisabled={type}
              getOptionValue={(option) => option["id"]}
              getOptionLabel={(option) => t(option["name"])}
              options={incidentTypeOptions}
              isMultiple={false}
              handleChange={(e) => {
                setIncidentType(e);
              }}
            />
          </div>
          {!type && (
            <>
              <div className="col-lg-3">
                <Combo
                  id="combIncidentLocation"
                  dataTestID="combIncidentLocation"
                  comboLabel={t("Location")}
                  inputValue={incidentLocation}
                  comboPlaceholder={t("Select") + " " + t("Location") + "..."}
                  defaultInputValue=""
                  isRequired={true}
                  isSearchable={false}
                  getOptionValue={(option) => option["id"]}
                  getOptionLabel={(option) => t(option["name"])}
                  options={incidentLocationOptions}
                  isMultiple={false}
                  handleChange={(e) => {
                    setIncidentLocation(e);
                  }}
                />
              </div>

              <div className="col-lg-3">
                <Input
                  dataTestID="txtAffectedArea"
                  inputName="txtAffectedArea"
                  inputType="text"
                  inputLabel={t("AffectedArea")}
                  inputID="incident-affected-area"
                  isDisabled={false}
                  onHandleChange={(e) => {
                    let val = e.currentTarget.value;
                    setAffectedArea(val);
                  }}
                  inputValue={affectedArea}
                  isRequired={true}
                />
              </div>
            </>
          )}
        </div>
        <div className="row">
          <div className="col-lg-6">
            <Textarea
              dataTestID="txtDescription"
              inputName="txtDescription"
              inputLabel={t("Description")}
              inputType="text"
              inputValue={incidentDescription}
              isDisabled={false}
              isRequired={true}
              inputOutline={true}
              onHandleChange={(e) => {
                setIncidentDescription(e.currentTarget.value);
              }}
            />
          </div>
        </div>
        {!type && (
          <>
            <p className="subtitle-media">{t("Multimedia")} </p>
            <div className="row">
              <div className="col-lg-6">
                <div className="uploader-wrapper">
                  <Uploader
                    isDisabled={false}
                    allowedExtensions={
                      ".jpg,.jpeg,.png,.gif,.tif,.webp,.bmp,.doc,.docx,.pdf"
                    }
                    setUploader={setUploader}
                  />
                </div>
              </div>
            </div>
          </>
        )}
        <p className="subtitle">{t("Contact")} </p>
        <div className="row">
          <div className="col-lg-4">
            <Input
              dataTestID="txtPhone"
              inputName="txtPhone"
              inputType="text"
              inputLabel={t("Phone")}
              inputID="tenant-phone"
              onHandleChange={(e) => {
                let val = e.currentTarget.value;
                setTenantPhoneNumber(val);
              }}
              value={tenantPhoneNumber}
              isRequired={true}
            />
          </div>
          <div className="col-lg-4">
            <Input
              dataTestID="txtEmail"
              inputName="txtEmail"
              inputType="text"
              inputLabel={t("Email")}
              inputID="tenant-email"
              onHandleChange={(e) => {
                let val = e.currentTarget.value;
                setTenantEmail(val);
                setEmailError(validateEmail(val).error);
              }}
              isError={emailError}
              errorMessage={
                tenantEmail === "" ? t("IsRequired") : t("EmailFormatIncorrect")
              }
              value={tenantEmail}
              isRequired={true}
            />
          </div>
          <div className="col-lg-4">
            <Combo
              id="cmbContactSchedule"
              dataTestID="cmbContactSchedule"
              comboLabel={t("ContactSchedule")}
              inputValue={contactSchedule}
              comboPlaceholder={
                t("Select") + " " + t("ContactSchedule") + "..."
              }
              defaultInputValue=""
              isRequired={true}
              isSearchable={false}
              getOptionValue={(option) => option["id"]}
              getOptionLabel={(option) => t(option["name"])}
              options={contactScheduleOptions}
              isMultiple={false}
              handleChange={(e) => {
                setContactSchedule(e);
              }}
            />
          </div>
        </div>
      </div>
      <div className="btn-end">
        <Button
          dataTestID="btnSaveSpace"
          btnText={t("SaveChanges")}
          btnType="button"
          btnClass="btn-base_normal"
          isDisabled={validateForm()}
          onHandleBtnClick={() => {
            postTenantIncident();
          }}
        />
      </div>
    </div>
  );
};
export default IncidentsTenantCreate;
